var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Chapters "),_c('v-btn',{attrs:{"to":"create","append":"","color":"accent","absolute":"","top":"","right":""}},[_vm._v(" Create new chapter ")])],1),_c('v-data-table',{attrs:{"items":_vm.chapters,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.lessonRows",fn:function(ref){
var item = ref.item;
return _vm._l((item.lessonRows),function(l,i){return _c('v-row',{key:i,staticClass:"pa-1",attrs:{"no-gutters":""}},[_vm._v(" ["),_vm._l((l.lessons.map(function (ref) {
	var id = ref.id;

	return _vm.getLessonById(id);
})),function(lesson,j){return _c('div',{key:lesson.id},[(lesson.name)?_c('span',[_vm._v(_vm._s(lesson.name)+" -")]):_c('span',{staticClass:"grey--text"},[_vm._v("Loading...")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(lesson.translation))]),(j !== l.lessons.length - 1)?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()])}),_vm._v("] ")],2)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":((item.id) + "/edit"),"append":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Edit chapter ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteChapter(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Delete chapter ")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }