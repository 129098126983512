






















































































import Vue from 'vue'
import {
  chapterStore,
  snackbarStore,
  lessonStore,
} from '@/store'
import {
  Chapter,
} from '@/types/chapter'
import {
  Id,
} from '@/types/base'
import {
  DataTableHeader,
} from 'vuetify'
import {
  Lesson,
} from '@/types/lesson'

export default Vue.extend({
  data: () => ({
    headers: [
      {
        text: `ID`,
        value: `id`,
      },
      {
        text: `Name`,
        value: `name`,
      },
      {
        text: `Translation`,
        value: `translation`,
      },
      {
        text: `Lessons`,
        value: `lessonRows`,
      },
      {
        text: ``,
        value: `actions`,
        sortable: false,
      },
    ] as DataTableHeader[],
  }),
  computed: {
    chapters (): (Chapter & Id)[] {
      return chapterStore.data
    },
  },
  watch: {
    chapters (chapters: (Chapter & Id)[]) {
      chapters.forEach((chapter) => {
        const lessonIds = chapter.lessonRows.flatMap((row) => row.lessons.map((l) => l.id))
        lessonStore.loadList(lessonIds)
      })
    },
  },
  async created () {
    try {
      await chapterStore.getAll()
    } catch (err) {
      snackbarStore.addSiteError({
        err,
        snackMessage: `Unable to load chapters`,
      })
    }
  },
  methods: {
    async deleteChapter (chapter: (Chapter & Id)) {
      if (!confirm(`Are you sure you want to delete "${chapter.name}"?`)) return
      try {
        await chapterStore.delete(chapter.id)
      } catch (err) {
        snackbarStore.addSiteError({
          err, snackMessage: `Error deleting chapter`,
        })
      }
    },
    getLessonById (id: string): (Lesson | Id) {
      return lessonStore.dataById(id) || {
        id,
      }
    },
  },
})
